<template>
  <div class="container login-container">
    <b-form @submit.stop.prevent="onSubmit">
      <router-link
        to="/"
        v-b-tooltip.hover
        :title="$t('NAV_HEADER.back_to_home')"
        class="my-4 d-block"
      >
        <b-img src="/fightback-logo-white.png" height="40"></b-img>
      </router-link>
      <h1>Cadastre-se</h1>
      <b-form-group
        label="Email"
        :description="hasSent && errorEmail ? `email já cadastrado` : ``"
      >
        <b-form-input
          type="email"
          v-model="user.email"
          required
          placeholder="Informe seu email"
          :state="hasSent && errorEmail ? false : null"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Senha:">
        <b-form-input
          type="password"
          v-model="user.password"
          required
          placeholder
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Confirme sua Senha"
        :description="hasSent && errorPassword ? `senhas não conferem` : ``"
      >
        <b-form-input
          type="password"
          v-model="user.password_confirmation"
          :state="hasSent && errorPassword ? false : null"
          required
          placeholder
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group id="exampleGroup4">
        <b-form-checkbox-group v-model="user.checked" id="exampleChecks">
          <b-form-checkbox value="me">Estou de acordo com os Termos de Uso</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>-->
      <b-button
        type="submit"
        :disabled="!submitEnabled"
        variant="danger"
        size="lg"
        >Cadastrar</b-button
      >
    </b-form>

    <div class="content-login">
      <h4>crie sua conta agora, <strong>gratuita</strong></h4>

      <p class="subtitle">e tenha acesso a</p>
      <ul class="list-unstyled">
        <!-- <li>
          <i class="fas fa-user-graduate"></i>
          <span>centenas de jogos de profissionais para estudo</span>
        </li> -->
        <li>
          <i class="fas fa-search"></i>
          <span>análise de seus próprios jogos</span>
        </li>
        <li>
          <i class="fas fa-comments"></i>
          <span>busca e comentários</span>
        </li>
        <li>
          <i class="fas fa-ellipsis-h"></i>
          <span>muito mais que preparamos para você</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signup",
  metaInfo() {
    return {
      title: "Cadastre-se",
    };
  },
  data: function () {
    return {
      user: {},
      errorEmail: false,
      errorPassword: false,
      hasSent: false,
      submitEnabled: true,
    };
  },
  methods: {
    onSubmit() {
      var self = this;
      self.submitEnabled = false;
      self.errorEmail = false;
      self.errorPassword = false;
      // self.hasSent = true;
      this.$http
        .post("users.json", { user: this.user })
        .then((response) => {
          var token = response.data.token;
          if (token) {
            this.$store
              .dispatch("login", { token: token, user: response.data.user })
              .then(() => {
                localStorage.setItem("token", token);
                if (response.data.user.nickname == null) {
                  this.$notify({
                    group: "all",
                    text: "Bem-vindo ao FIGHTBACK! Vamos preencher seu perfil?",
                    type: "success",
                    duration: -1000,
                  });
                  this.$router.push(
                    `/users/${response.data.user.id}/edit?onboarding`
                  );
                } else this.$router.push("/");
              });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            var errors = error.response.data.error;
            if (errors.email) self.errorEmail = true;
            if (errors.password_confirmation) self.errorPassword = true;
            self.submitEnabled = true;
            self.hasSent = true;
          }
        });
    },
  },
  components: {},
};
</script>

<style lang="sass" scoped>
.login-container
  margin-top: 20px
  margin-bottom: 20px
  color: white
  margin: 60px auto 10px

  form
    max-width: 340px
    margin: 60px auto 10px

    h1, .form-signin-heading
      color: rgba(255, 255, 255, .85)
      font-weight: 500
      margin-bottom: 2rem

  .content-login
    padding: 40px 20px
    max-width: 380px
    margin: 2rem auto 10px
    h4
      margin-bottom: 0px
    p.subtitle
      margin: 0
      font-size: 16px
    ul
      li
        display: flex
        margin: 20px 0
        align-items: center
        i
          width: 32px
          height: 32px
          font-size: 24px
          position: relative
          top: 4px
          margin-right: 10px
        span
          display: block
          font-size: 16px
          line-height: 125%
</style>
